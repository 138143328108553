import { createAsyncThunk } from "@reduxjs/toolkit";
import { ThunkConfig } from "../../../../app/providers/StoreProvider";
import { USER_TOKEN_KEY } from "../../../../shared/lib/const/localstorage";
import { UserActions } from "../slices/UserSlice";
import { RefreshTokenResponse } from "../types/UserSchema";

export const fetchRefreshToken = createAsyncThunk<RefreshTokenResponse, void, ThunkConfig<string>>(
    'user/refreshToken',
    async (_, thunkApi) => {
        const { extra, rejectWithValue, dispatch } = thunkApi;

        try {
            const userToken = localStorage.getItem(USER_TOKEN_KEY);

            // Если токен отсутствует, завершить выполнение без отправки запроса
            if (!userToken) {
                return rejectWithValue('User token is missing');
            }

            // Выполнение запроса на обновление токена
            const response = await extra.api.post<RefreshTokenResponse>(`/auth/renew_token`, {
                userToken
            });

            // Диспатч успешной авторизации и обновление токена в локальном хранилище
            dispatch(UserActions.setAuth(true));
            // dispatch(fetchUserTags());
            localStorage.setItem(USER_TOKEN_KEY, response.data.userToken);

            return response.data;
        } catch (e: any) {
            // Возврат ошибки
            return rejectWithValue(e.message);
        } finally {
            // Завершение инициализации авторизации
            dispatch(UserActions.initAuth());
        }
    },
);
