import { useSelector } from 'react-redux'
import { getUserIsAuth } from '../../../../entities/User'

interface RequireAuthProps {
    children: JSX.Element
    roles?: any[]
}

export function RequireAuth({ children, roles }: RequireAuthProps) {
    const isAuth = useSelector(getUserIsAuth)

    if (!isAuth) {
        return <div>not found</div>
    }

    return children
}
